import React, { useRef } from "react";
import Modal from "react-modal";
import ContactForm from "../components/ContactForm";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    maxHeight: "90vh",
    width: "95%",
    maxWidth: "700px",
  },
};

Modal.setAppElement(`body`);

function ContactFormModal({ modalOpen, onModalClose }) {
  const targetRef = useRef(null);

  function closeModal() {
    onModalClose(false);
  }

  return (
    <div ref={targetRef}>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        closeTimeoutMS={300}
        style={customStyles}
        contentLabel="Free Estimate"
        className="Modal bg-gray-50"
        overlayClassName="Overlay"
      >
        <div className="px-8 py-6">
          <div className="py-4 flex items-start justify-between ">
            <div>
              <h2 className="font-display font-bold leading-8 text-xl md:text-3xl md:mb-1">Get a FREE Estimate</h2>
              <p className="text-gray-600 text-sm md:text-base leading-5 md:leading-6 mb-2 hidden md:inline">
              Reach out to us today to explore our premium siding and window installation services, proudly serving the Bay Area. At Bay Area Siding Company, we’re dedicated to transforming your home with exceptional quality and care!

              </p>
            </div>
            <button
              onClick={closeModal}
              className="bg-gray-50 focus:outline-none focus:bg-gray-100 hover:bg-gray-100 transition duration-150 ease-in rounded-full p-2"
            >
              <svg
                className="text-gray-900 w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <ContactForm showPlaceholders={true} hideLabels={true} footerContained={false} grayInputBg={true} />
        </div>
      </Modal>
    </div>
  );
}

export default ContactFormModal;
