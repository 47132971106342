import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Footer from "./footer";
import Promotion from "./promotion";
import ProductLogoShowcase from "./product-logo-showcase";
import "./layout.css";
import "../styles/index.scss";
import "../styles/output.css";
import "../styles/custom.scss";
import "../styles/global.css";

import { adRefCity } from "../helpers/adRefTracking";
import Navbar from "./Navbar";

const Layout = ({ children, pageProps, footerLogoShowcase, isHomePage, customSchema, headerTransparent, headerShadow }) => {
  if (typeof window !== "undefined" && window.localStorage && !window.localStorage.getItem("landingURL")) {
    window.localStorage.setItem("landingURL", pageProps.location.href);
    window.localStorage.setItem("landingDate", new Date());
  }
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              description
              companyInfo {
                phone
                email
                addressLine1
                addressLine2
                city
                state
                zip
                license
              }
            }
          }
          allContentfulNavigation(filter: { name: { eq: "Main Navigation" } }) {
            edges {
              node {
                id
                name
                menuItems {
                  id
                  name
                  title
                  url
                  openInNewWindow
                  submenu {
                    id
                    name
                    menuItems {
                      id
                      name
                      title
                      url
                      openInNewWindow
                    }
                  }
                }
                campaign
              }
            }
          }
        }
      `}
      render={(data) => (
        <>
          <Navbar
            siteTitle={data.site.siteMetadata.title}
            siteDescription={adRefCity(data.site.siteMetadata.description)}
            companyInfo={data.site.siteMetadata.companyInfo}
            pageProps={pageProps}
            headerTransparent={headerTransparent}
            shadow={headerShadow}
          />

          {/* <Header
            siteTitle={data.site.siteMetadata.title}
            siteDescription={adRefCity(data.site.siteMetadata.description)}
            companyInfo={data.site.siteMetadata.companyInfo}
            mainNavigation={
              data.allContentfulNavigation.edges.filter(
                ({ node: { campaign } }) =>
                  campaign.length > 0 ? campaign[0] === 'default' : false
              )[0].node.menuItems
            }
            menus={data.allContentfulNavigation.edges}
            pageProps={pageProps}
          /> */}

          <div className="layout-container">{children}</div>
          <ProductLogoShowcase footerLogoShowcase={footerLogoShowcase} />
          <Footer isHomePage={isHomePage} />
          <Promotion />
          {!customSchema && (
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: `
              {
                "@id": "https://www.bayareasidingco.com/#HomeAndConstructionBusiness",
                "@context": "https://www.schema.org",
                "@type": "HomeAndConstructionBusiness",
                "name": "Bay Area Siding Company",
                "url": "https://www.bayareasidingco.com/",
                "logo": "https://images.ctfassets.net/xa41rilplmsh/5V4Bf6tnTqMOG1SNoiX26D/9d73b7a32fcaca5d3413f37086170d7a/basc-logo.png",
                "image": "https://images.ctfassets.net/xa41rilplmsh/5V4Bf6tnTqMOG1SNoiX26D/9d73b7a32fcaca5d3413f37086170d7a/basc-logo.png",
                "description": "Bay Area Siding Company is a locally owned and operated full-service siding, windows, and exterior renovations contractor in the Bay Area, CA, specializing in siding installation, window replacement, and complete exterior makeovers for your residential, commercial, and industrial projects.







                ",
                "address": {
                    "@type": "PostalAddress", 
                    "streetAddress": "529 Magnolia Ave",
                    "addressLocality": "Larkspur",
                    "addressRegion": "CA",
                    "postalCode": "94939",
                    "addressCountry": "US"
                },
  
                "openingHours": "Mo, Tu, We, Th, Fr 08:00-18:00",
                "contactPoint": {
                    "@type": "ContactPoint",
                    "contactType": "sales",
                    "telephone": "+1 415-619-5167"
                }
            }`,
              }}
            />
          )}
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;

//classes for tailwind to include when doing purge
// pr-2
